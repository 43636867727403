import React from "react";
import { FaDiscord } from "react-icons/fa";

function About() {
  return (
    <div className="relative">
      <div className="container flex items-center justify-center">
        <div className="box-styling max-w-[580px] lg:max-w-[680px] xl:max-w-[846px] w-full mx-auto rounded-2xl py-10 lg:py-14 px-8 sm:px-12 lg:px-20 relative">
          <h1 className="text-center text-2xl sm:text-3xl lg:text-4xl 2xl:text-5xl text-white font-semibold mb-4 lg:mb-6 xl:mb-8 2xl:mb-9">
             WHAT IT’S <span className="text-green">ABOUT</span>
          </h1>
          <p className="text-xs sm:text-sm lg:text-base xl:text-lg 2xl:text-2xl text-white leading-[1.6] text-center">
            <b>A collection of 4900 unique hand drawing NFT. </b>
          </p>
          <br/>
          <p className="text-xs sm:text-sm lg:text-base xl:text-lg 2xl:text-2xl text-white leading-[1.6] text-center">
            On April 19, 1943, Albert Hofmann, the Swiss father of psychedelic medicine,
            dropped Lysergic Acid Diethylamide and went on a bike ride,
            becoming the first human to ever trip on LSD. The rest is a legendary psychedelic history.
            This unique hand designed collection is inspired by that story and the overall history of LSD.{" "}
          </p>

          <img
            src="images/about-horn.png"
            className="absolute bottom-[100%] left-1/2 -translate-x-1/2 md:translate-x-0 md:left-[-10%] w-[140px] sm:w-[180px] lg:w-[220px] xl:w-[300px] 2xl:w-[364px]"
            alt=""
          />
        </div>
      </div>

      <img
        src="images/green-blob.png"
        className="absolute top-1/2 -translate-y-1/2 right-[-30%] -z-100 w-[80%]"
        alt=""
      />

      <img
        src="images/purple-blob.png"
        className="absolute top-1/2 -translate-y-1/2 left-[-30%] -z-100 w-[80%]"
        alt=""
      />
      <div className="flex md:justify-between justify-center">
       <img
          src="images/lsd.svg"
          className=" md:w-[10%] md:max-w-none"
          alt=""
        />
        <div className="mt-10 ">
         <a
            href="https://discord.gg/HEfSEyDFtS"
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer flex align-center justify-center gradient-button px-6 items-center py-4 md:w-full w-200px"
          >
            <FaDiscord className="text-white text-xl mr-4" /> JOIN OUR DISCORD
          </a>
        </div>
       <img
          src="images/lsd.svg"
          className=" md:w-[10%] md:max-w-none"
          alt=""
        />
        </div>
    </div>
  );
}

export default About;
