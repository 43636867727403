import React from "react";
import Accordion from "../components/Accordion";

function Faq() {
  return (
    <div className="container">
      <div className="bg-darkBlack pb-[40px]  py-[24px] sm:py-[40px] lg:py-[40px] px-[24px] sm:px-[40px] lg:px-[80px] border-2 border-mahron sm:rounded-[34px]">
        <h1 className="text-center text-white font-display text-[30px] lg:text-[50px] font-black mb-[30px] sm:mb-[60px]">
          FAQ
        </h1>

        <div className="space-y-6">
          <Accordion
            title="WHAT IS A NFT (NON-FUNGIBLE TOKEN)?"
            desc="NFTs (non-fungible tokens) are unique cryptographic tokens that exist on a blockchain and cannot be replicated. NFTs can represent real-world items like artwork and real estate. 'Tokenizing' these real-world tangible assets makes buying, selling, and trading them more efficient while reducing the probability of fraud."
          />
          <Accordion
            title="HOW DO I MINT/PURCHASE A BICYCLE DAY CLUB?"
            desc="We want to make this process as easy as buying any other product online. At the time of our public sale, all you need to do is go to our website bicycledayclub.com, click on the 'Mint' button and then check out with your credit card and email or using your crypto wallet. The best way to save your spot to purchase at mint is by joining our Discord community. Get lightlisted in our community to get access to our presale mint! There are only a limited number of NFTs available and buying them at presale will ensure you get the best price before they sell out."
          />
          <Accordion
            title="WHAT DETERMINES THE RARITY AND VALUE OF EACH NFT?"
            desc="Rarity is an important factor in understanding the value of an individual NFT on the secondary marketplace. Each NFT is a combination of handmade artistic layers / traits and depending upon these traits that are generated there will be some NFT that are more elusive than others. A 3rd party platform can rank the rarity of each NFT within the NFT collection. We will recommend the best rarity ranking tool once we are on the secondary marketplace."
          />
          <Accordion
            title="WHAT ARE THE BENEFITS OF OWNING THIS NFT"
            desc="BICYCLE DAY CLUB NFTs are a membership token to our club, granting access to members-only benefits including future drops, giveaways, raffles, merchandise, and more!"
          />
        </div>
      </div>
    </div>
  );
}

export default Faq;
