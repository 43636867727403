import Title from "components/Title";
import UtilityCard from "components/UtilityCard";
import React from "react";

function Utility() {
  return (
    <div>
      <div className="container">
        <div className="mb-12 lg:mb-16 xl:mb-20">
          <div className="mb-4 lg:mb-6 xl:mb-8">
            <Title>
              THE <span className="text-green">UTILITY</span>
            </Title>
          </div>

          <p className="text-[20px] lg:text-[24px] text-center text-white max-w-[35em] mx-auto leading-[1.5] opacity-80 font-light">
            We invest tremendous amounts of effort into increasing the value that we can grant to our community.
            We want to bring together Psychonauts, Psychedelic therapists, Psychedelic Organizations and individuals who feel part or curious about being part of this collaboration.
            We already put together exclusive upcoming psychedelic events and parties for the community, exclusive benefits and massive discounts with some of our online commerce partners, Whitelisting for new launches, investment into psychedelic assisted therapies,
            merchandise and plans for much more to come up with more partnerships and collaborations <b>let's grow together.</b>
          </p>
        </div>

        <div className="relative grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-y-6 lg:gap-y-0 w-full max-w-full xs:max-w-[26rem] lg:max-w-none mx-auto lg:mx-0 items-stretch">
          <UtilityCard
            img="images/eye-1.png"
            desc="In Person Events, we love to gather and we are creating our own music and intellectual events for our members and also collaborate with other organizations."
          />
          <UtilityCard
            img="images/eye-2.png"
            desc="Exclusive 50% discounts for every member on our partner’s online cannabis and wellness products store: WWW.KAYAVAX.COM and other collaborations"
          />
          <UtilityCard
            img="images/eye-1.png"
            desc="Airdrop & Whitelisting for new launches: we’re not stopping here, new projects are on the que for our members."
          />
          <UtilityCard
            img="images/eye-2.png"
            desc="Strategic investment into organizations researching and developing psychedelic assisted therapies."
          />

          <img
            src="images/green-blob.png"
            className="absolute top-1/2 -translate-y-1/2 right-[-10%] w-[90%] -z-100"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Utility;
