import TeamCard from "components/TeamCard";
import Title from "components/Title";
import React from "react";

function Team() {
  return (
    <div className="relative overflow-visible">
      <div className="container mb-24">
        <div className="mb-12 lg:mb-16">
          <Title>
            The Psychonauts <span className="text-green">Team</span>
          </Title>
        </div>
        <div className="grid  mx-auto md:mx-0 mx-w-full md:grid-cols-3 gap-6 lg:gap-10">
          <TeamCard
            title="Psychonauts T"
            img="images/team-img-1.png"
            desc="Tam Cali, based in Berkeley, CA. Studied art at the Academy of Art University in San Francisco. Owner and founder of KayaVax cannabis products and active in the psychedelic scene in San Francisco since 2008."
          />
          <TeamCard
            title="Psychonauts N"
            img="images/team-img-2.png"
            desc="Nir is an experienced blockchain and web3 developer with a deep interest in consciousness. He has been exploring the human experience for many years and has created several projects to exercise the limits of human perception."
          />
          <TeamCard
            title="Psychonauts D"
            img="images/team-img-3.png"
            desc="Daniel is a profound artist that create amazing psychedelic art for more then 10 years and continue creating new ways in painting."
          />
        </div>
      </div>
      <div className="container">
        <div className="mb-12 lg:mb-16">
          <Title>
            Collaborations<span className="text-green"></span>
          </Title>
        </div>
        <div className="grid max-w-[18rem] mx-auto md:mx-0 md:max-w-none md:grid-cols-3 gap-6 lg:gap-10">
          <img src="images/kayaVaxLogo.png" className="h-full w-1/2"/>
        </div>
      </div>
      <img
        src="images/team-bg.png"
        className="absolute top-0 left-1/2 -translate-x-1/2 w-full h-full -z-100 object-cover"
        alt=""
      />
    </div>
  );
}

export default Team;
