import React, { useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

function Accordion({ title, desc }) {
  const [open, setOpen] = useState(false);
  const accordionBodyRef = useRef(null);

  const toggler = () => {
    const accordion = accordionBodyRef.current
    if (accordion.clientHeight === 0) {
      accordion.style.height =
        accordion.scrollHeight + "px";
      setOpen(true);
    } else {
      accordion.style.height = 0 + "px";
      setOpen(false);
    }
  };

  return (
    <div className="bg-grayBlue rounded-[20px]">
      <button
        className="flex justify-between items-center w-full py-2 lg:py-2 px-8 lg:px-10 pr-4 lg:pr-6 space-x-6 text-left"
        onClick={toggler}
      >
        <h1 className="text-[20px] sm:text-[20px] lg:text-[22px] font-display font-[700] text-white">
          {title}
        </h1>

        <div className="flex justify-center items-center bg-[#222234] min-w-[40px] min-h-[40px] lg:w-[55px] lg:h-[55px] rounded-[12px]">
          <span
            className={`transition-all text-[20px] lg:text-[30px] duration-[.3s] ease-[ease] ${
              open ? "rotate-[180deg]" : "rotate-0"
            }`}
          >
            <IoIosArrowDown color="white" />{" "}
          </span>
        </div>
      </button>

      <div
        className="h-0 overflow-hidden transition-all duration-[.3s] ease-[ease]"
        ref={accordionBodyRef}
      >
        <div className="lg:pb-8 pb-6 px-8 lg:px-10">
          <p className="text-[18px] font-roboto text-white opacity-[.5] font-normal">
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Accordion;
