import { useState, useRef, useEffect } from "react";
import RoadmapCard from "components/RoadmapCard";
import Title from "components/Title";

function Roadmap() {
  const roadmap = useRef(null);
  const roadmapLine = useRef(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const handler = () => {
      let a = window.scrollY - roadmap.current["offsetTop"];
      let value = a + window.innerHeight / 2;
      setValue(value);
    };

    window.addEventListener("scroll", handler);

    return () => {
      window.removeEventListener("scroll", handler);
    };
  });

  return (
    <div className="relative py-14" ref={roadmap}>
      <div className="container">
        <div className="mb-14 md:mb-18 xl:mb-20">
          <Title>
            ROAD MAP TO THE <span className="text-green">MOON</span>
          </Title>
        </div>

        <div className="grid grid-cols-2 w-full max-w-[936px] mx-auto relative">
          <div className="roadmap-line rounded-full absolute top-0 left-1/2 -translate-x-1/2 w-1 h-full bg-transparent overflow-y-hidden">
            <div
              ref={roadmapLine}
              className="w-full bg-green"
              style={{ height: value + "px" }}
            ></div>
          </div>

          <div className="space-y-20">
            <RoadmapCard
              direction="left"
              heading="phase-01"
              title="Planning"
              desc="Quality comes first. we took our time to plan out everything and create high quality art"
            />
            <RoadmapCard
              direction="left"
              heading="phase-03"
              title="Private Sale"
              desc="We want to make sure the member that really on board with our vision get the option to get a discounted price"
            />
            <RoadmapCard
              direction="left"
              heading="phase-05"
              title="Let's go public - it's minting time"
              desc="The public sale will open with a celebrated event"
            />
             <RoadmapCard
              direction="left"
              heading="phase-05"
              title="What next ..."
              desc="Let's decide this together as community from events to cool merchandise, to support psychedelic projects"
            />
          </div>
          <div className="space-y-12 pt-36 lg:pt-80px">
            <RoadmapCard
              direction="right"
              heading="phase-02"
              title="Community"
              desc="Community is everything, and we wannt to make sure we grow together and listen to what our community want (family style)"
            />
            <RoadmapCard
              direction="right"
              heading="phase-04"
              title="Whitelist time"
              desc="Before the big public sale we will give the option to our communty memebr to join the whitelist and secure thier place in the sale"
            />
            <RoadmapCard
              direction="right"
              heading="phase-06"
              title="THE HARD COPY OF YOUR NFT"
              desc="We will ship you the PERFORATED BLOTTER of your art to your home"
            />
          </div>
        </div>
      </div>

      <img
        src="images/roadmap-bg.png"
        className="absolute top-0 left-0 w-full h-full -z-100 object-contain"
        alt=""
      />
    </div>
  );
}

export default Roadmap;
