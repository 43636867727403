import Title from "components/Title";
import React from "react";
import { FaDiscord } from "react-icons/fa";

function JoinPrivateSale() {
  return (
    <div>
      <div className="container">
        <div className="join-private-sales max-w-[500px] xl:max-w-[800px] w-full rounded-2xl mx-auto py-12 xl:py-16 px-6 md:px-9 flex flex-col items-center text-center">
          <div className="mb-8 xl:mb-10">
            <Title>
              Join the private <span className="text-green">Sale</span>
            </Title>
          </div>

          <button className="gradient-button py-3 xl:py-4 px-6 xl:px-8 text-xs xl:text-base">
            Coming soon
          </button>
        </div>
        <div className="mt-10 w-full flex flex-col items-center text-center">
            <p className="my-5 text-2xl">For more updates and join the community join our discord server</p>
          <a href="https://discord.gg/HEfSEyDFtS" target="_blank" rel="noreferrer"
            className="w-20 h-20 flex items-center justify-center text-white relative overflow-hidden group bg-light-gray z-10">
            <div className="bg-green-to-blue absolute top-0 left-0 w-full h-full transition-all duration-[.3s] opacity-0 group-hover:opacity-100 -z-10"></div>
                <FaDiscord className="text-white text-5xl" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default JoinPrivateSale;
