import React from "react";

function BlotingPaper() {
  return (
    <div className="relative">
      <div className="container flex flex-col md:flex-row items-center space-y-12 md:space-y-0 md:space-x-16 2xl:space-x-28 max-w-[24rem] md:max-w-none">
        <div className="flex-1">
          <h1 className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-white font-semibold mb-6 xl:mb-10 capitalize leading-[1.3]">
            PERFORATED BLOTTER:<br/><span className="text-green">THE HARD COPY</span> OF YOUR NFT
          </h1>

          <p className="text-[20px] lg:text-[24px] text-white max-w-[35em] mx-auto leading-[1.5] opacity-80 font-light">
            Each one of our NFT art pieces to be minted, will be also physically printed and perforated on a traditional 15cmx15cm (225 1cmX1cm dose) blotter paper sheet and sent to its owner, the Member.
          </p>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <img
            src="images/puzzel.png"
            className="w-full md:w-[90%] xl:w-[70%]"
            alt=""
          />
        </div>
      </div>

      <img
        src="images/green-blob.png"
        className="absolute top-1/2 -translate-y-1/2 left-[-40%] w-[90%] -z-100"
        alt=""
      />
    </div>
  );
}

export default BlotingPaper;
