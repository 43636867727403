import Title from "components/Title";
import React from "react";

function UniqueHand() {
  return (
    <div>
      <div className="mb-5 md:mb-6 lg:mb-10">
        <div className="container">
          <div>
            <Title>
              HAND DRAWN <span className="text-green">DESIGN</span>
            </Title>
          </div>
        </div>
      </div>
      <div className="relative pb-20 lg:pb-36 xl:pb-200px pt-5 md:pt-6 lg:pt-10">
        <img
          src="images/hand-design-bg.png"
          className="absolute top-[-20%] sm:top-[-12%] md:top-0 left-0 w-full h-full object-cover -z-100"
          alt=""
        />

        <div className="container flex flex-col md:flex-row items-center md:space-x-8 space-y-8 md:space-y-0">
          <div className="md:flex-[.8] lg:flex-1 flex items-center justify-center w-full">
            <img
              src="images/cycle.png"
              className="w-full md:w-[90%] 2xl:w-[470px] max-w-[10rem] sm:max-w-[16rem] md:max-w-none"
              alt=""
            />
          </div>
          <div className="flex-1 text-center md:text-left max-w-[26rem] md:max-w-none mx-auto md:mx-0 bg-light-green-gradient rounded-2xl py-8 lg:py-10 px-8">
            <h2 className="text-xl lg:text-2xl xl:text-3xl font-semibold text-white mb-4 lg:mb-6 xl:mb-8">
              HAND DRAWN <span className="text-green">DESIGN</span>
            </h2>

            <p className="text-xs lg:text-base xl:text-xl text-white leading-[1.5] mb-4 lg:mb-6 xl:mb-8">
              Our NFT collection is made by unique hand drawn designs,
              depicting the famous art of Albert Hofmann riding his bike while experiencing the
              effects of LSD for the first time in history.
            </p>
            <p className="text-xs lg:text-base xl:text-xl text-white leading-[1.5] mb-6">
              Each one of the visual elements creating those NFT’s was carefully hand drawn and inspired by different psychedelic cultural art.
            </p>
          </div>
        </div>
      </div>
      <div className="mb-8 md:mb-6 lg:mb-12">
        <div className="container flex flex-col md:flex-row items-center md:space-x-8 space-y-8 md:space-y-0">
          <div className="text-xs lg:text-base xl:text-xl text-white leading-[1.5]">
            <div className="mb-10">
              <p className="text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-white font-semibold pb-8">
                About the <span className="text-green">Artist</span>
              </p>
              <p>Daniel Philosoph, a versatile artist who moves between different mediums. <br />He sees painting as a form of meditation, enjoys being in the moment and believes that the path itself should be as beautiful as the end result. <br />His works exceed all imagination, he draws inspiration from science and treats the canvas as a petri dish.</p>
            </div>
            <div className="relative grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-y-6 lg:gap-y-0 w-full max-w-full xs:max-w-[26rem] lg:max-w-none mx-auto lg:mx-0 gap-2">
              <img
                src="images/art-1.png"
                className="h-full object-cover -z-100"
                alt=""
              />
               <img
                src="images/art-2.png"
                className="h-full object-cover -z-100"
                alt=""
              />
               <img
                src="images/art-3.png"
                className="h-full object-cover -z-100"
                alt=""
              />
            </div>

          </div>
          <img
            src="images/daniel.png"
            className="h-full object-cover -z-100"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default UniqueHand;
